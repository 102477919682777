@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Codystar:wght@300&display=swap');

.App {
  text-align: center;
}

/* NAVBAR STYLING */

#links_container {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

#nav_container {
  align-items: center;
  background-color: #0a063b;
  box-shadow: 0 4px 4px -4px rgba(209, 207, 209, 0.2);
  color: #ffffff;
  display: flex;
  height: 10vh;
  justify-content: center;
  padding: 0 5%;
  position: fixed;
  width: 100%;
  z-index: 1;
}

#nav_container a {
  text-decoration: none;
}

#nav_container a:hover {
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px;
}

/* INTRO STYLING */

#about_container {
  display: flex;
}

#about_left {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 50%;
}

#about_left h1 {
  animation: glitch 5s 5s infinite;
  background: linear-gradient(to right, #30CFD0, #c43ad6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-size: 3.5em;
  text-shadow: 0 0 40px rgba(192, 219, 255, 0.473), 0 0 32px rgb(65 120 255 / 24%);
}

#about_left h1::before {
  animation: noise-1 3s linear infinite alternate-reverse, glitch 5s 5.05s infinite;
}

#about_left h1::after {
  animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
}

#about_left h2 {
  margin: 0;
  font-size: 1.2em;
  font-weight: lighter;
}

#about_left h3 {
  margin: 0;
  font-size: 1em;
  font-weight: lighter;
}

#about_right {
  align-items: center;
  background-image: url('./images/pattern.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 50%;
}

#about_right img {
  border-radius: 50%;
  -moz-box-shadow: 1px 1px 10px rgb(28, 2, 224);
  -webkit-box-shadow: 1px 1px 5px rgb(197, 7, 255);
  box-shadow:  1px 1px 15px rgb(158, 3, 247);
  height: 300px;
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 10s 1s infinite;
}

.scanlines {
  overflow: hidden;
  mix-blend-mode: difference;
}

.scanlines::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.05) .5%,
    transparent 1%
  );

  animation: fudge 7s ease-in-out alternate infinite;
}

.sign {
  background-image: radial-gradient(
    ellipse 15% 35% at 50% 50%,
    #6b1839,
    transparent
  );
  letter-spacing: 2;
  font-family: 'Audiowide', cursive;
  text-transform: uppercase;
  font-size: 6em;
  color: #ffe6ff;
  animation: shine 2s forwards\, flicker 3s infinite;
}

/* TECH PAGE */

#logos_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5%;
  width: 80%;
}

#logos_container img {
  height: 80px;
}

#tech_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 63vh;
}

#tech_container .sign {
  font-size: 2em;
  margin-top: 5%;
}

#tech_header {
  animation: glitch_2 5s linear infinite;
  color: #e422b6;
  font-size: 3em;
  font-weight: bold;
  margin-top: 10%;
}

.neons h1 {
  font-family: 'Codystar', cursive;
  font-size: 2.5rem;
  text-align: center;
   font-weight: bold;
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

/* PORTFOLIO */
#portfolio_container {
  margin-bottom: 5%;
}
.project_description_left {
  color: #ffffff;
  margin-left: 5%;
  text-align: left;
}

.project_description_left a, .project_description_right a {
  color: #04f7ff;
  text-decoration: none;
}

.project_description_right {
  color: #ffffff;
  margin-right: 5%;
  text-align: left;
}

.project_row_left {
  display: flex;
  margin: 5% 10% 0;
}

.project_row_right {
  display: flex;
  flex-direction: row-reverse;
  margin: 5% 10% 0;
}

.project_preview img {
  height: 350px;
}

/* RESUME */

#mobile_resume {
  display: none;
}

#resume_wrapper {
  display: flex;
  justify-content: center;
}

/* CONTACT */

#form_container {
  margin: 0 auto 100px;
  width: 30%;
}

#form_input_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 34vh;
  justify-content: space-evenly;
}

.contact_btn {
  background: none;
  border: 1px solid #21ebff;
  box-shadow: 0 0 5px #21ebff, 0 0 5px #21ebff inset;
  color: #21ebff;
  font-size: 0.8em;
  padding: 1.5% 4%;
  transition: all 0.3s ease;
}
.contact_btn:hover{
   text-shadow:
    0 0 20px #21ebff,
    0 0 50px rgba(33, 235, 255, .9),
    0 0 75px rgba(33, 235, 255, .8),
    0 0 76px rgba(33, 235, 255, .7),
    0 0 77px rgba(33, 235, 255, .6),
    0 0 78px rgba(33, 235, 255, .5),
    0 0 79px rgba(33, 235, 255, .4),
    0 0 80px rgba(33, 235, 255, .3),
    0 0 85px rgba(33, 235, 255, .2),
     0 0 99px rgba(33, 235, 255, .1);
}

.form_line {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.form_line input, .form_line textarea {
  background: transparent;
  border: 1px solid #ff65bd;
  border-radius: 5px;
  color: #00fff2;
  font-family: 'Red Hat Text', sans-serif;
  padding: 1.5% 1%;
  width: 100%;
}

.form_line input:focus, .form_line textarea:focus {
  background: transparent;
}

.form_line input::placeholder, .form_line textarea::placeholder {
  color: #ffffff80;
}

.form_line span {
  font-size: 0.8em;
  margin-bottom: 2%;
}


/* KEYFRAMES */

@keyframes glow {
  from {
   color: #fff;
 text-shadow: 0 0 10px #00fff2, 0 0 20px #00fff2, 0 0 30px #00fff2, 0 0 40px #00fff2, 0 0 50px #00fff2, 0 0 60px #00fff2, 0 0 70px #00fff2, 0 0 90px #00fff2;
}

to {
  color: rgb(21, 216, 242);
 text-shadow: 0 0 20px #00fff2, 0 0 30px #00fff2, 0 0 40px #00fff2, 0 0 50px #00fff2, 0 0 60px #00fff2, 0 0 70px #00fff2, 0 0 80px #00fff2, 0 1 90px #00fff2;
}
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

@keyframes glitch {
  1%{
    transform: rotateX(10deg) skewX(90deg);
  }
  2%{
    transform: rotateX(0deg) skewX(0deg);
  }
}

@keyframes noise-1 {
  3.3333333333% {
    clip-path: inset(81px 0 1px 0);
 }
  6.6666666667% {
    clip-path: inset(37px 0 11px 0);
 }
  10% {
    clip-path: inset(58px 0 16px 0);
 }
  13.3333333333% {
    clip-path: inset(89px 0 12px 0);
 }
  16.6666666667% {
    clip-path: inset(13px 0 20px 0);
 }
  20% {
    clip-path: inset(47px 0 31px 0);
 }
  23.3333333333% {
    clip-path: inset(46px 0 18px 0);
 }
  26.6666666667% {
    clip-path: inset(81px 0 13px 0);
 }
  30% {
    clip-path: inset(62px 0 26px 0);
 }
  33.3333333333% {
    clip-path: inset(97px 0 3px 0);
 }
  36.6666666667% {
    clip-path: inset(65px 0 22px 0);
 }
  40% {
    clip-path: inset(64px 0 7px 0);
 }
  43.3333333333% {
    clip-path: inset(84px 0 10px 0);
 }
  46.6666666667% {
    clip-path: inset(4px 0 47px 0);
 }
  50% {
    clip-path: inset(31px 0 6px 0);
 }
  53.3333333333% {
    clip-path: inset(16px 0 33px 0);
 }
  56.6666666667% {
    clip-path: inset(78px 0 5px 0);
 }
  60% {
    clip-path: inset(79px 0 21px 0);
 }
  63.3333333333% {
    clip-path: inset(57px 0 18px 0);
 }
  66.6666666667% {
    clip-path: inset(33px 0 66px 0);
 }
  70% {
    clip-path: inset(20px 0 21px 0);
 }
  73.3333333333% {
    clip-path: inset(72px 0 17px 0);
 }
  76.6666666667% {
    clip-path: inset(8px 0 42px 0);
 }
  80% {
    clip-path: inset(55px 0 12px 0);
 }
  83.3333333333% {
    clip-path: inset(97px 0 2px 0);
 }
  86.6666666667% {
    clip-path: inset(47px 0 50px 0);
 }
  90% {
    clip-path: inset(91px 0 10px 0);
 }
  93.3333333333% {
    clip-path: inset(62px 0 27px 0);
 }
  96.6666666667% {
    clip-path: inset(23px 0 76px 0);
 }
  100% {
    clip-path: inset(48px 0 30px 0);
 }
}

@keyframes noise-2 {
  0% {
    clip-path: inset(72px 0 10px 0);
 }
  3.3333333333% {
    clip-path: inset(12px 0 86px 0);
 }
  6.6666666667% {
    clip-path: inset(59px 0 25px 0);
 }
  10% {
    clip-path: inset(57px 0 18px 0);
 }
  13.3333333333% {
    clip-path: inset(13px 0 54px 0);
 }
  16.6666666667% {
    clip-path: inset(7px 0 7px 0);
 }
  20% {
    clip-path: inset(6px 0 31px 0);
 }
  23.3333333333% {
    clip-path: inset(80px 0 5px 0);
 }
  26.6666666667% {
    clip-path: inset(23px 0 32px 0);
 }
  30% {
    clip-path: inset(71px 0 8px 0);
 }
  33.3333333333% {
    clip-path: inset(20px 0 62px 0);
 }
  36.6666666667% {
    clip-path: inset(15px 0 70px 0);
 }
  40% {
    clip-path: inset(54px 0 40px 0);
 }
  43.3333333333% {
    clip-path: inset(71px 0 10px 0);
 }
  46.6666666667% {
    clip-path: inset(6px 0 29px 0);
 }
  50% {
    clip-path: inset(62px 0 16px 0);
 }
  53.3333333333% {
    clip-path: inset(32px 0 42px 0);
 }
  56.6666666667% {
    clip-path: inset(56px 0 13px 0);
 }
  60% {
    clip-path: inset(12px 0 71px 0);
 }
  63.3333333333% {
    clip-path: inset(14px 0 76px 0);
 }
  66.6666666667% {
    clip-path: inset(100px 0 1px 0);
 }
  70% {
    clip-path: inset(60px 0 41px 0);
 }
  73.3333333333% {
    clip-path: inset(37px 0 55px 0);
 }
  76.6666666667% {
    clip-path: inset(89px 0 8px 0);
 }
  80% {
    clip-path: inset(1px 0 21px 0);
 }
  83.3333333333% {
    clip-path: inset(49px 0 25px 0);
 }
  86.6666666667% {
    clip-path: inset(37px 0 38px 0);
 }
  90% {
    clip-path: inset(19px 0 64px 0);
 }
  93.3333333333% {
    clip-path: inset(39px 0 50px 0);
 }
  96.6666666667% {
    clip-path: inset(86px 0 1px 0);
 }
  100% {
    clip-path: inset(78px 0 1px 0);
 }
}

@keyframes fudge {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 2%);
  }
}

/* MEDIA QUERIES */

@media (max-width: 790px) {
  #about_container {
    flex-direction: column-reverse;
  }

  #about_left {
    height: 30vh;
    width: 100%;
  }

  #about_right {
    width: 100%;
  }

  #form_container {
    width: 75%;
  }

  #mobile_resume {
    color: #fff;
    display: block;
  }

  #mobile_resume a {
    color: #21ebff;
    text-decoration: none;
  }

  #resume_wrapper {
    display: none;
  }
}

@media  (max-width: 500px) {

  #about_left h1 {
    font-size: 2.4em;
  }

  #about_left h2 {
    font-size: 1em;
  }

  #about_left h3 {
    font-size: 0.8em;
  }

  #about_right {
    height: 70vh;
    width: 100%;
  }

  #about_right img {
    height: 250px;
    margin-top: 15%;
  }

  #nav_container {
    padding: 0;
  }

  #links_container {
    width: 85%;
  }

  #logos_container {
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-bottom: 100px;
  }

  #logos_container img {
    height: auto;
    width: 45px;
  }

  #portfolio_header {
    font-size: 3em;
  }

  #tech_container {
    flex-direction: column;
    height: 16vh;
    margin-bottom: 50%;
  }

  .neons h1 {
    font-size: 0.6em;
  }

  .project_description_left, .project_description_right {
    text-align: center;
  }

  .project_description_left p, .project_description_right p, .project_description_left span, .project_description_right span {
    font-size: 0.8em;
  }

  .project_preview img {
    height: 200px;
  }

  .project_row_left {
    flex-direction: column;
  }

  .project_row_right {
    flex-direction: column;
  }
}